import React from 'react'
import './Content.css'

export default function Content(props) {
  return (
    <div><center>          
      <div className='Area'>
        <span className='Title'><br/>{props.page}<br/></span>
        <p className='Body'><center><br/><br/><br/>(Page Content Page Content Page Content)</center></p>
        {props.children}
      </div>      
    </center></div>
  )
}
