/*
  Title: Navigation Bar
  Customized by: BG
  Last Modified: 17/05/2024
*/

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './NavBar.css'

function NavScrollExample() {
  return (
    <Navbar expand="lg" className="navbar">
      <Container fluid>
        <Navbar.Brand className='logo' href="/">SAMPLE PROJECT</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          
          <Nav
            className="pages"
            navbarScroll
          >
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/About">ABOUT</Nav.Link>
            <Nav.Link href="/Galary">GALARY</Nav.Link>
            <Nav.Link href="/Count">COUNTER</Nav.Link>
          </Nav>

          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="search"
              aria-label="Search"
            />
            <Button className='btn custom-button' variant="outline-dark">SEARCH</Button>
          </Form>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;