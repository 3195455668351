
/*
Note: The first letter of the component file name should be simple 
      and The first letter of the component name should be capital
*/

import AboutPage from "./Pages/AboutPage";
import CounterPage from "./Pages/CounterPage";
import GalaryPage from "./Pages/GalaryPage";
import HomePage from "./Pages/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>

          <Route path="/" element = {<HomePage/>}></Route>
          <Route path="/About" element = {<AboutPage/>}></Route>
          <Route path="/Galary" element = {<GalaryPage/>}></Route>
          <Route path="/Count" element = {<CounterPage/>}></Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
