import React from 'react'
import Content from '../Components/Content'
import { Link } from 'react-router-dom'
import NavBar from '../Components/NavBar'
import './AboutPage.css'

export default function AboutPage() {
  function buttonClick(){
    console.log("Show More Info!");
    alert("This is a sample project. No more infomations!");
  }
  return (
    <div>
      <NavBar/>
      <Content page = "ABOUT PAGE">
        <button 
          onClick={buttonClick}
          type="button" 
          class="btn btn-outline-secondary">
            Read More
        </button>
      </Content>
    </div>
  )
}
