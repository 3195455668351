import React from 'react'
import Content from '../Components/Content'
import { Link } from 'react-router-dom'
import './CounterPage.css'
import { useState } from 'react'
import NavBar from '../Components/NavBar'

export default function CounterPage() {
  const [count, setCount] = useState(0);

  const increment = () => {
    setCount(count+1);
  };

  const decrement = () => {
    setCount(count-1);
  };

  return (
    <div>
      <NavBar/>
      <Content page="COUNTER PAGE">        
        <h2 className='text'>The count is {count} <br/></h2>
        <button className='btn' onClick={decrement}> - </button>
        <span> </span>
        <button onClick={increment} className='btn' > + </button>
      </Content><br/>
    </div>
  )
}
