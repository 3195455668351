import React from 'react'
import Content from '../Components/Content'
import NavBar from '../Components/NavBar'
import { Link } from 'react-router-dom'

export default function HomePage() {
  return (
    <div>
        <NavBar/>
        <Content page = "HOME PAGE"/>        
    </div>
  )
}
