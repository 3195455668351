import React from 'react'
import Content from '../Components/Content'
import { Link } from 'react-router-dom'
import NavBar from '../Components/NavBar'

export default function GalaryPage() {
  return (
    <div>
      <NavBar/>
      <Content page="GALARY PAGE"/>
    </div>
  )
}
